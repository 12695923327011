import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Homepage } from '../utils/navLinks'
import Link from '../components/link'

import Error from '../assets/svg/error.svg'

function NotFoundPage() {
    const { t } = useTranslation('error404')

    return (
        <Layout>
            <SEO title="error404:title" description="error404:message" />
            <div className="errorPage">
                <Error className="errorImage" />
                <div>
                    <h1 className="pageTitle">{t('title')}</h1>
                    <p>{t('message')}</p>
                    <Button
                        to={Homepage.path}
                        component={Link}
                        variant="contained"
                        disableElevation
                        className="homeButton"
                        startIcon={<HomeIcon />}
                    >
                        {t('link')}
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
